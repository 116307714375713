import React, { useState } from "react"
import {
  flexRender,
  getFilteredRowModel,
  getCoreRowModel,
  useReactTable,
  VisibilityState,
  getSortedRowModel,
  OnChangeFn,
  ColumnFiltersState,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table"
import "./style.css"

interface CustomTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  pageStartIndex: number
  pageMaxSize: number
  columnsVisibility: VisibilityState
  globalFilter?: string
  setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>
  columnFilters?: ColumnFiltersState
  setColumnFilters?: OnChangeFn<ColumnFiltersState>
}

export default function CustomTable({
  columns,
  data,
  pageStartIndex,
  pageMaxSize,
  columnsVisibility,
  globalFilter,
  setGlobalFilter,
  columnFilters,
  setColumnFilters,
}: CustomTableProps) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageStartIndex,
    pageSize: pageMaxSize,
  })
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(columnsVisibility)
  const table = useReactTable({
    data,
    columns,
    state: { pagination, globalFilter, columnFilters, columnVisibility },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
  })
  const { pageSize, pageIndex } = table.getState().pagination
  const currentPageItems = table.getRowModel().rows.length
  const totalItemsFiltered = table.getFilteredRowModel().rows.length

  return (
    <div className="custom-table-wrapper">
      <table className="custom-table">
        <thead className="custom-table-head">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="custom-table-head-row">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={
                    header.column.getCanSort() ? "custom-table-head-cell-allow-sort" : "custom-table-head-cell"
                  }
                >
                  <div style={{ display: "flex" }}>
                    {header.isPlaceholder ? null : (
                      <div style={{ marginRight: "5px" }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <span>&#9660;</span>
                      ) : (
                        <span>&#9650;</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="custom-table-body">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="custom-table-body-row">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="custom-table-body-cell">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="elements-info">
          Mostrate {pageSize * pageIndex + currentPageItems} righe di {totalItemsFiltered}
        </div>
        <div className="pagination-info">
          Pagina {pageIndex + 1} di {table.getPageCount().toLocaleString()}
        </div>
        <button className="btn pagination-btn" onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()}>
          <span>&#10094;</span>
          <span>&#10094;</span>
        </button>
        <button
          className="btn pagination-btn"
          style={{ marginLeft: "5px" }}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <span>&#10094;</span>
        </button>
        <button
          className="btn pagination-btn"
          style={{ marginLeft: "5px" }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span>&#10095;</span>
        </button>
        <button
          className="btn pagination-btn"
          style={{ marginLeft: "5px" }}
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          <span>&#10095;</span>
          <span>&#10095;</span>
        </button>
      </div>
    </div>
  )
}
