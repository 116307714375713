interface OrderResultPageHeaderProp {
  title: string
  subtitle?: string
}

export default function GiftCardPageHeader({ title, subtitle }: OrderResultPageHeaderProp) {
  return (
    <div className="d-md-flex justify-content-center align-items-center" style={{ paddingTop: "32px" }}>
      <div className="text-center text-md-start">
        <img
          src={`${process.env.PUBLIC_URL}/img/giftcard-details_header.svg`}
          alt="Purchase logo"
          id="purchase_logo"
          style={{ marginLeft: "32px", marginRight: "32px" }}
        />
      </div>
      <div className="text-center text-md-start">
        <h2>{title}</h2>
        {subtitle ? <h5>{subtitle}</h5> : null}
      </div>
    </div>
  )
}
