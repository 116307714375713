import { useEffect, useState } from "react"
import { GetOrderDetailsDto, LineItem } from "../models/api/ordersApiTypes"
import { Link, useParams } from "react-router-dom"
import { getOrderDetailsApi } from "../api/ordersApi"
import { toast } from "react-toastify"
import { createColumnHelper } from "@tanstack/react-table"
import OrderResultPageHeader from "../components/order/OrderResultPageHeader"
import CustomTable from "../components/common/CustomTable"
import OrderSkeleton from "../components/order/OrderSkeleton"

export default function OrderDetailsPage() {
  const [orderResult, setOrderResult] = useState<GetOrderDetailsDto>()
  const { orderId } = useParams()
  const priceMultiplier = 100
  const isDiscountPresent = orderResult?.payment.discount !== undefined ? orderResult.payment.discount > 0 : false

  useEffect(() => {
    getOrderDetailsApi(orderId)
      .then((result: GetOrderDetailsDto) => {
        setOrderResult(result)
      })
      .catch(() => toast.warning("Qualcosa è andato storto!"))
  }, [orderId])

  function formatDate(dateString: string): string {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  function formatHour(dateString: string): string {
    const date = new Date(dateString)
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${hours}:${minutes}`
  }

  const columnHelper = createColumnHelper<LineItem>()
  const columns = [
    columnHelper.accessor("name", {
      header: () => "NOME PRODOTTO",
      cell: (name) => name.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("description", {
      header: () => "DESCRIZIONE",
      cell: (description) => description.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("quantity", {
      header: () => "QUANTITA'",
      cell: (quantity) => quantity.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("price", {
      header: () => "PREZZO",
      cell: (price) => <>{price.getValue() / priceMultiplier}€</>,
      enableSorting: false,
    }),
  ]

  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>

      <OrderResultPageHeader title={"Ordine"} subtitle={`${orderId}`} />

      {orderResult ? (
        <div style={{ maxWidth: "576px", textAlign: "justify" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
          <h4 className="mt-4">Informazioni sul tuo ordine</h4>
          <div className="row">
            <div className="col-12 mb-4">
              Ordine effettuato da {orderResult.customerName} ({orderResult.customerEmail}) in data{" "}
              {formatDate(orderResult.created)} alle ore {formatHour(orderResult.created)}
            </div>
          </div>
          <CustomTable
            data={orderResult.items}
            columns={columns}
            columnsVisibility={{}}
            pageStartIndex={0}
            pageMaxSize={5}
          />
          <div className="row text-center" style={{ marginTop: "30px" }}>
            <div className={isDiscountPresent ? "col-sm-12 col-lg-2 mb-4" : "col-sm-12 col-lg-3 mb-4"}>
              <b>Stato</b> <br /> {orderResult.payment.status}
            </div>
            <div className={isDiscountPresent ? "col-sm-12 col-lg-4 mb-4" : "col-sm-12 col-lg-3 mb-4"}>
              <b>Metodo di pagamento</b> <br /> {orderResult.payment.paymentMethod}
            </div>
            <div className={isDiscountPresent ? "col-sm-12 col-lg-2 mb-4" : "col-sm-12 col-lg-3 mb-4"}>
              <b>Totale ordine</b> <br /> {orderResult.total / priceMultiplier}€
            </div>
            <div className={isDiscountPresent ? "col-sm-12 col-lg-2 mb-4" : "col-sm-12 col-lg-3 mb-4"}>
              <b>Totale pagato</b> <br /> {orderResult.payment.amount / priceMultiplier}€
            </div>
            {isDiscountPresent ? (
              <div className="col-sm-12  col-lg-2 mb-4">
                <b>Sconto</b> <br /> {orderResult.payment.discount / priceMultiplier}€
              </div>
            ) : null}
          </div>
          <div className="row" style={{ marginTop: "20px", fontSize: "13px" }}>
            <div className="col-12 mb-4">
              Ultima modifica in data {formatDate(orderResult.lastUpdate)} alle ore {formatHour(orderResult.lastUpdate)}
            </div>
          </div>
        </div>
      ) : (
        <OrderSkeleton />
      )}
    </div>
  )
}
