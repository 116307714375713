export default function OrderPageHeader() {
  return (
    <div className="d-md-flex justify-content-center align-items-center" style={{ paddingTop: "32px" }}>
      <div className="text-center text-md-start">
        <img
          src={`${process.env.PUBLIC_URL}/img/purchase_header.svg`}
          alt="Purchase logo"
          id="purchase_logo"
          style={{ marginLeft: "32px", marginRight: "32px" }}
        />
      </div>
      <div className="text-center text-md-start">
        <h2>Acquista una Gift Card</h2>
      </div>
    </div>
  )
}
