import "./style.css"

interface AllowedAmountProps {
  selectedAmount: string
  onSelectAmount: (value: string) => void
}

export default function AllowedAmount({ selectedAmount, onSelectAmount }: AllowedAmountProps) {
  const presetAmounts = [50, 100, 150, 200]
  const otherAmount = "Altro"

  return (
    <div style={{ display: "flex" }}>
      {presetAmounts.map((amount) => (
        <div
          key={amount}
          className={selectedAmount === amount.toString() ? "amount-selected" : "amount"}
          onClick={() => onSelectAmount(amount.toString())}
        >
          {amount}
        </div>
      ))}
      <div
        className={selectedAmount === otherAmount ? "amount-selected" : "amount"}
        onClick={() => onSelectAmount(otherAmount)}
      >
        {otherAmount}
      </div>
    </div>
  )
}
