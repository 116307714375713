import { GetGiftCardGraphicsDetailsDto } from "../../../models/api/giftCardsApiTypes"
import Carousel from "react-multi-carousel"
import "./style.css"

interface GiftCardGraphicsCarouselProps {
  graphics: GetGiftCardGraphicsDetailsDto[]
  selectedGraphic: string
  onSelectGraphic: (value: string) => void
}

export default function GiftCardGraphicsCarousel({
  graphics,
  selectedGraphic,
  onSelectGraphic,
}: GiftCardGraphicsCarouselProps) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return graphics.length > 0 ? (
    <Carousel responsive={responsive}>
      {graphics.map((graphic, index) => (
        <div
          key={index}
          className={
            graphic.id === selectedGraphic
              ? "gift-card-graphics-img-container-selected"
              : "gift-card-graphics-img-container"
          }
          onClick={() => onSelectGraphic(graphic.id)}
          tabIndex={0}
        >
          <img src={graphic.frontUrl} className="gift-card-graphics-img object-fit-cover p-2" alt="Immagine negozio" />
        </div>
      ))}
    </Carousel>
  ) : null
}
