import { Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Home from "./pages/Home"
import RegistrationPage from "./pages/RegistrationPage"
import OrderPage from "./pages/OrderPage"
import OrderResultPage from "./pages/OrderResultPage"
import ShopListPage from "./pages/ShopListPage"
import FaqPage from "./pages/FaqPage"
import ShopPage from "./pages/ShopPage"
import OrderDetailsPage from "./pages/OrderDetailsPage"
import GiftCardDetailsPage from "./pages/GiftCardDetailsPage"
import "react-toastify/dist/ReactToastify.css"
import "react-loading-skeleton/dist/skeleton.css"
import "bootstrap/dist/js/bootstrap.bundle"

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <Routes>
          <Route path="/negozi" element={<ShopListPage />} />
          <Route path="/registrazione" element={<RegistrationPage />} />
          <Route path="/domande-frequenti" element={<FaqPage />} />
          <Route path="/negozio/:shopSlug" element={<ShopPage />} />
          <Route path="/acquista-gift-card" element={<OrderPage />} />
          <Route path="/ordine" element={<OrderResultPage />} />
          <Route path="/ordine/:orderId" element={<OrderDetailsPage />} />
          <Route path="/giftcard/:giftCardId" element={<GiftCardDetailsPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  )
}

export default App
