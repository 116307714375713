import { useState } from "react"
import { useForm } from "react-hook-form"
import { CreateOrderDto, CreateOrderPaymentResponseDto } from "../../models/api/ordersApiTypes"
import { createOrderApi, createOrderPaymentApi } from "../../api/ordersApi"
import { toast } from "react-toastify"
import { GetGiftCardGraphicsDetailsDto } from "../../models/api/giftCardsApiTypes"
import AllowedAmount from "./AllowedAmount"
import GiftCardGraphicsCarousel from "./GiftCardGraphicsCarousel"
import OrderTermsConditions from "./OrderTermsConditions"
import cn from "classnames"

type OrderFormType = {
  customerName: string
  customerEmail: string
  graphic: string
  price: string
  customPrice: string | undefined
  message: string
  acceptTerms: boolean
}

interface OrderFormProps {
  giftCardGraphics: GetGiftCardGraphicsDetailsDto[]
}

export default function OrderForm({ giftCardGraphics }: OrderFormProps) {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<OrderFormType>()
  const [isCustomAmount, setIsCustomAmount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const selectedGraphic = watch("graphic")
  const selectedAmount = watch("price")
  const messageValue = watch("message")
  const messageMaxChars = 250
  const minimumAmount = 20
  const maximumAmount = 200

  const handleSelectAmount = (value: string) => {
    setValue("price", value)
    setIsCustomAmount(value === "Altro")
    if (value !== "Altro") {
      setValue("customPrice", undefined)
    }
    clearErrors("price")
  }

  const handleSelectGraphic = (value: string) => {
    setValue("graphic", value)
    clearErrors("graphic")
  }

  const handleShowModal = () => setShowTerms(true)

  const handleCloseModal = () => setShowTerms(false)

  const onSubmit = async (data: OrderFormType) => {
    const priceMultiplier = 100
    const itemPrice: number = (data.customPrice ? parseInt(data.customPrice) : parseInt(data.price)) * priceMultiplier
    if (isNaN(itemPrice)) {
      toast.error("L'importo inserito non è valido!")
      return
    }
    const createOrderDto: CreateOrderDto = {
      customerName: data.customerName,
      customerEmail: data.customerEmail,
      value: itemPrice,
      graphicId: selectedGraphic,
      message: data.message,
      recipientName: data.customerName,
      recipientEmail: data.customerEmail,
      deliveryDate: null /* new Date().toISOString(), */,
      validFrom: null /* new Date().toISOString(), */,
    }

    setIsLoading(true)
    createOrderApi(createOrderDto)
      .then((orderId: string) => {
        createOrderPaymentApi(orderId, {
          successUrl: `${process.env.REACT_APP_BASE_URL}/ordine?order_id=${orderId}`,
          cancelUrl: `${process.env.REACT_APP_BASE_URL}/acquista-gift-card`,
          paymentMethod: "STRIPE",
          notes: "",
        })
          .then((response: CreateOrderPaymentResponseDto) => {
            toast.success("Stai per essere reindirizzato alla pagina di pagamento.")
            window.location.href = response.paymentUrl
          })
          .catch(() => {
            toast.error("Qualcosa è andato storto. Riprova!")
          })
          .finally(() => setIsLoading(false))
      })
      .catch(() => {
        toast.error("Qualcosa è andato storto. Riprova!")
        setIsLoading(false)
      })
  }

  const onError = () => {
    toast.error("Compila correttamente tutti i campi e riprova.")
  }

  return (
    <div style={{ maxWidth: "576px" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
      <OrderTermsConditions show={showTerms} onClose={handleCloseModal} />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <fieldset disabled={isLoading}>
          <h4 className="mt-4">Scegli un design per la tua Gift Card</h4>
          <div className="row">
            <div className="col-12 mb-4">
              <GiftCardGraphicsCarousel
                graphics={giftCardGraphics}
                selectedGraphic={selectedGraphic}
                onSelectGraphic={handleSelectGraphic}
              />
              <input
                type="hidden"
                className={cn("form-control", { "is-invalid": errors.graphic })}
                {...register("graphic", { required: "Seleziona una grafica" })}
              />
              {errors.graphic && <div className="invalid-feedback">{errors.graphic.message}</div>}
            </div>
          </div>
          <h4 className="mt-1">Scegli un importo</h4>
          <div className="row">
            <div className="col-12 mb-4">
              <AllowedAmount selectedAmount={selectedAmount} onSelectAmount={handleSelectAmount} />
              <input
                type="hidden"
                className={cn("form-control", { "is-invalid": errors.price })}
                {...register("price", { required: "Seleziona un importo o inseriscilo premendo 'Altro'" })}
              />
              {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
            </div>
          </div>

          {isCustomAmount ? (
            <div className="row">
              <div className="col-12 mb-4">
                <label htmlFor="ownerName" className="form-label">
                  Scrivi l&apos;importo
                </label>
                <input
                  placeholder="Importo desiderato"
                  type="number"
                  className={cn("form-control", { "is-invalid": errors.customPrice })}
                  {...register("customPrice", {
                    required: "Inserisci l'importo",
                    min: { value: minimumAmount, message: `Il valore minimo è ${minimumAmount} euro` },
                    max: { value: maximumAmount, message: `Il valore minimo è ${maximumAmount} euro` },
                  })}
                />
                {errors.customPrice && <div className="invalid-feedback">{errors.customPrice.message}</div>}
              </div>
            </div>
          ) : null}

          <h4 className="mt-1">A chi la mandiamo?</h4>
          <p>
            Attenzione! L&apos;indirizzo email che inserisci qui sotto riceverà un PDF con la grafica selezionata,
            l&apos;importo, ed un QR Code da scannerizzare in negozio. Potrai anche stampare il PDF e portarlo
            fisicamente in negozio.
          </p>
          <div className="row">
            <div className="col-12 mb-4">
              <label htmlFor="customerName" className="form-label">
                Nome Ricevente
              </label>
              <input
                placeholder="Mario Rossi"
                type="text"
                className={cn("form-control", { "is-invalid": errors.customerName })}
                {...register("customerName", { required: "Nome richiesto" })}
              />
              {errors.customerName && <div className="invalid-feedback">{errors.customerName.message}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <label htmlFor="customerEmail" className="form-label">
                Email Ricevente
              </label>
              <input
                placeholder="mario.rossi@buonisulserio.it"
                type="text"
                className={cn("form-control", { "is-invalid": errors.customerEmail })}
                {...register("customerEmail", {
                  required: "Inserisci un indirizzo email",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Inserisci un indirizzo valido",
                  },
                })}
              />
              {errors.customerEmail && <div className="invalid-feedback">{errors.customerEmail.message}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-4">
              <label htmlFor="message" className="form-label">
                Messaggio
              </label>
              <textarea
                maxLength={messageMaxChars}
                style={{ resize: "none" }}
                placeholder="Inserisci qua il tuo messaggio..."
                rows={6}
                className="form-control"
                {...register("message", { maxLength: messageMaxChars })}
              />
              <div className="mt-2" style={{ fontSize: "14px", opacity: "80%" }}>
                Caratteri rimanenti: {messageMaxChars - messageValue?.length}
              </div>
            </div>
          </div>
        </fieldset>
        <div className="row">
          <div className="col-12 mb-4">
            <input
              style={{ marginRight: "10px" }}
              type="checkbox"
              className={errors.acceptTerms ? "is-invalid" : ""}
              {...register("acceptTerms", { required: "Accetta i Termini e le Condizioni" })}
            />
            Accetto i{" "}
            <u style={{ cursor: "pointer", color: "blue" }} onClick={handleShowModal}>
              Termini e le Condizioni
            </u>
            {errors.acceptTerms && <div className="invalid-feedback">{errors.acceptTerms.message}</div>}
          </div>
        </div>
        <div className="d-grid gap-2 mt-2 mb-4 text-center">
          <div>
            {!isLoading ? (
              <input type="submit" className="btn btn-violet" value="Acquista ora" />
            ) : (
              <button type="submit" className="btn btn-violet">
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Caricamento...
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
