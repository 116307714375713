import Skeleton from "react-loading-skeleton"

export default function GiftCardSkeleton() {
  return (
    <div style={{ maxWidth: "576px" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
      <h4 className="mt-4">
        <Skeleton />
      </h4>
      <div className="row">
        <Skeleton height={40} />
      </div>
      <h4 className="mt-4">
        <Skeleton />
      </h4>
      <div className="row">
        <Skeleton height={40} />
      </div>
      <h4 className="mt-4">
        <Skeleton />
      </h4>
      <div className="row">
        <Skeleton height={150} />
      </div>
    </div>
  )
}
