import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import OrderResultPageHeader from "../components/order/OrderResultPageHeader"

export default function OrderResultPage() {
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get("order_id") ?? ""
  const [mailto, setMailto] = useState("")

  useEffect(() => {
    setMailto(`mailto:supporto@buonisulserio.it?subject=Richiesta%20di%20Informazioni%20Ordine:%20${orderId}`)
  }, [orderId])

  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>

      <OrderResultPageHeader title={`Complimenti il tuo ordine è andato a buon fine.`} />

      <div style={{ maxWidth: "576px", textAlign: "justify" }} className="mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4">
        <p>
          Il tuo numero d&apos;ordine è: <Link to={`/ordine/${orderId}`}>{orderId}</Link>
        </p>
        <p>
          Riceverai a breve una email con allegato un pdf contente il QR Code da mostrare presso gli esercenti che
          aderiscono a Buoni Sul Serio (controlla anche nella SPAM).
        </p>
        <p>
          Per qualsiasi necessità non esitare a contattare il supporto di Buoni Sul Serio (
          <a href={mailto}>supporto@buonisulserio.it</a>) indicando nell&apos;oggetto della email il numero del tuo
          ordine.
        </p>
      </div>
    </div>
  )
}
