import React, { useState } from "react"
import { getGiftCardDetailsByCodeApi } from "../../api/giftCardsApi"
import { useNavigate } from "react-router-dom"
import { PostGiftCardCheckResultDto } from "../../models/api/giftCardsApiTypes"
import { toast } from "react-toastify"
import useCheckMobileResolution from "../../hook/useCheckMobileResolution"

export default function Footer() {
  const [code, setCode] = useState("")
  const navigate = useNavigate()
  const isMobile = useCheckMobileResolution()

  const hanldeFigtCardCodeClick = () => {
    if (code.length === 0) return
    getGiftCardDetailsByCodeApi({ code })
      .then((result: PostGiftCardCheckResultDto) => {
        navigate(`/giftcard/${result.id}`)
      })
      .catch(() => {
        toast.error("Qualcosa è andato storto. Assicurati che il codice sia corretto e riprova!")
      })
  }
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value)
  }

  return (
    <footer>
      <div className="row p-4">
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <div className="align-self-center footer-left-item">&copy;2024 BuoniSulSerio</div>
        </div>
      </div>
      <div className="row">
        <div style={{ paddingLeft: "100px", paddingRight: "100px" }}>
          <hr />
        </div>
      </div>
      <div className="row p-4">
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <div className="d-inline-flex">
            <div className={isMobile ? `align-self-center` : `align-self-center footer-left-item me-lg-4`}>
              <img
                src={`${process.env.PUBLIC_URL}/img/footer/icons/logo_distretto.png`}
                alt="Logo distretto"
                style={{ width: "200px" }}
              />
            </div>
            <div className="d-none d-lg-block" style={{ fontSize: "13px" }}>
              Associazione del distretto del commercio Alta Valle Seriana
              <br />
              Piazza Sant&apos;Andrea 1 - 24023 - Clusone (BG)
              <br />
              P.IVA 03928980162 - C.F. 03928980162
              <br />
              Telefono 0346 72220
              <br />
              E-mail distrettodelcommercioaltavalle@gmail.com
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 mt-4 mt-lg-0 text-center">
          <div className="d-inline-flex" style={{ flexDirection: "column" }}>
            <div className="text-lg-start" style={{ fontSize: "13px" }}>
              Inserisci il codice della tua GiftCard
            </div>
            <div className="d-inline-flex" style={{ flexDirection: "row" }}>
              <input
                placeholder="AAAA-BBBB-CCCC"
                type="text"
                className="form-control me-1"
                onChange={handleCodeChange}
              />
              <img
                style={{ cursor: "pointer" }}
                src={`${process.env.PUBLIC_URL}/img/footer/icons/right-arrow.svg`}
                alt="RightArrow"
                onClick={hanldeFigtCardCodeClick}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 mt-4 mt-lg-0 text-center text-lg-end">
          <div style={{ marginLeft: "100px", marginRight: "100px" }}>
            <div className="d-inline-flex">
              <div className="me-4">
                <a href="https://link.buonisulserio.it/go/social/fb?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/facebook.svg`} alt="Facebook" />
                </a>
              </div>
              <div className="me-4">
                <a href="https://link.buonisulserio.it/go/social/yt?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/youtube.svg`} alt="Youtube" />
                </a>
              </div>
              <div>
                <a href="https://link.buonisulserio.it/go/social/ig?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/instagram.svg`} alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
