import {
  GetGiftCardDetailsDto,
  GetGiftCardGraphicsDetailsDto,
  PostGiftCardCheckDto,
  PostGiftCardCheckResultDto,
} from "../models/api/giftCardsApiTypes"
import { api } from "./clientApi"

export function getGiftCardGraphicsApi() {
  return new Promise<GetGiftCardGraphicsDetailsDto[]>((resolve, reject) => {
    api
      .get("/GiftCards/graphics")
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}

export function getGiftCardDetailsApi(giftCardId: string | undefined) {
  return new Promise<GetGiftCardDetailsDto>((resolve, reject) => {
    api
      .get(`/GiftCards/${giftCardId}`)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}

export function getGiftCardDetailsByCodeApi(payload: PostGiftCardCheckDto) {
  return new Promise<PostGiftCardCheckResultDto>((resolve, reject) => {
    api
      .post("/GiftCards/check", payload)
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
