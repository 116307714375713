import { Modal } from "react-bootstrap"

interface OrderTermsConditionsProps {
  show: boolean
  onClose: () => void
}
export default function OrderTermsConditions({ show, onClose }: OrderTermsConditionsProps) {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Termini e condizioni di utilizzo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>
            Le GiftCard possono essere utilizzate solo nelle attività che hanno aderito all&apos;iniziativa Buoni Sul
            Serio.
          </li>
          <li>
            Le GiftCard saranno spendibili presso le attività aderenti all&apos;iniziativa Buoni Sul Serio a partire dal
            13 dicembre 2024.
          </li>
          <li>
            Le GiftCard hanno validità di 1 anno dalla data di acquisto o dal 13 dicembre nel caso in cui vengano
            acquistate prima di tale data.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" className="btn btn-violet" onClick={onClose}>
          Chiudi
        </button>
      </Modal.Footer>
    </Modal>
  )
}
