import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { getGiftCardDetailsApi } from "../api/giftCardsApi"
import { GetGiftCardDetailsDto, TransactionDetailsDto } from "../models/api/giftCardsApiTypes"
import { createColumnHelper } from "@tanstack/react-table"
import GiftCardPageHeader from "../components/giftcard/GiftCardPageHeader"
import CustomTable from "../components/common/CustomTable"
import GiftCardSkeleton from "../components/giftcard/GiftCardSkeleton"

export default function GiftCardDetailsPage() {
  const [giftCard, setGiftCard] = useState<GetGiftCardDetailsDto>()
  const [isMobile, setIsMobile] = useState(false)
  const { giftCardId } = useParams()
  const priceMultiplier = 100
  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 786)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    getGiftCardDetailsApi(giftCardId)
      .then((result: GetGiftCardDetailsDto) => {
        setGiftCard(result)
      })
      .catch(() => toast.warning("Qualcosa è andato storto!"))
  }, [giftCardId])

  function formatDate(dateString: string): string {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  function formatHour(dateString: string): string {
    const date = new Date(dateString)
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${hours}:${minutes}`
  }

  const columnHelper = createColumnHelper<TransactionDetailsDto>()
  const columns = [
    columnHelper.accessor("shopName", {
      header: () => "NOME ATTIVITA'",
      cell: (shopName) => shopName.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      header: () => "SPESA",
      cell: (amount) => <>{amount.getValue() / priceMultiplier}€</>,
      enableSorting: false,
    }),
    columnHelper.accessor("redeemedAt", {
      header: () => "DATA SPESA",
      cell: (redeemedAt) => (
        <>
          {formatDate(redeemedAt.getValue())}- {formatHour(redeemedAt.getValue())}
        </>
      ),
      enableSorting: false,
    }),
  ]

  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>

      <GiftCardPageHeader title={`Gift Card ${giftCard ? "di " + giftCard.recipientName : ""}`} />
      {giftCard ? (
        <div
          style={{ maxWidth: "576px", textAlign: "justify" }}
          className={`mx-auto mt-0 mb-0 mt-sm-4 mb-sm-4 ${isMobile ? "text-center" : ""}`}
        >
          <h4 className="mt-4">Informazioni sulla tua GiftCard</h4>
          <div className="row">
            <div className="col-12 mb-4">
              Ciao {giftCard.recipientName} di seguito alcuni dettagli sulla tua GiftCard.
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
              <b>Valore GiftCard</b> <br /> {giftCard.value / priceMultiplier}€
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
              <b>Valore rimanente</b> <br /> {giftCard.remainingValue / priceMultiplier}€
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
              <b>Valida da</b> <br /> {formatDate(giftCard.validFrom)}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
              <b>Valida fino a</b> <br /> {formatDate(giftCard.expirationDate)}
            </div>
          </div>
          {giftCard.transactions.length > 0 ? (
            <CustomTable
              data={giftCard.transactions}
              columns={columns}
              columnsVisibility={{}}
              pageStartIndex={0}
              pageMaxSize={5}
            />
          ) : null}
        </div>
      ) : (
        <GiftCardSkeleton />
      )}
    </div>
  )
}
