import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { GetGiftCardGraphicsDetailsDto } from "../models/api/giftCardsApiTypes"
import { getGiftCardGraphicsApi } from "../api/giftCardsApi"
import { toast } from "react-toastify"
import OrderForm from "../components/order/OrderForm"
import OrderPageHeader from "../components/order/OrderPageHeader"
import OrderSkeleton from "../components/order/OrderSkeleton"

export default function OrderPage() {
  const [giftCardGraphics, setGiftCardGraphics] = useState<GetGiftCardGraphicsDetailsDto[]>()

  useEffect(() => {
    getGiftCardGraphicsApi()
      .then((result) => setGiftCardGraphics(result))
      .catch(() => toast.error("Qualcosa è andato storto. Prova a ricaricare la pagina."))
  }, [])

  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>
      <OrderPageHeader />
      {giftCardGraphics ? <OrderForm giftCardGraphics={giftCardGraphics} /> : <OrderSkeleton />}
    </div>
  )
}
